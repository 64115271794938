import React, { Component } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, TextField, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import { FACILITY_DETAILS_URL, AMENITY_BOOKINGS_SAVE_URL, AADHAR_IMG_URL } from '../Utility/URL';
import { apiCall, date_format, SweetAlert } from '../Utility/utils';
import Header from '../Header';
import { Mixpanel } from '../Mixpanel';
import TermsAndConditions from './TermsAndConditions';
import CounterButton from '../Common/Buttons/CounterButton';
import AdharUpload from '../Common/Uploads/AdharUpload';
import {isAndroid, isIOS} from 'react-device-detect';
import "./index.css"

const styles = {
  container: {
    backgroundColor: '#FBFBFB',
    minHeight: '100vh',
    padding: '1rem',
    width:'100%',
    fontFamily:'Jost'
  },
  label: {
    fontSize:'16px',
    lineHeight:'36px',
    fontWeight:'400',
    fontFamily:'Jost'
  },
  keyTextContainer: {
    backgroundColor:'#FFFFFF',
    padding:'0.5rem 1rem',
    marginBottom:'2rem'
  },
  keyText: {
    fontSize:'16px',
    lineHeight:'36px',
    fontWeight:'500',
    fontFamily:'Jost', 
    color:'#000000'
  }
}

class CottageTimeSelection extends Component {
  constructor(){
    super();
    this.state = {
      styles: {
        is_date_disable: true,
        display_preview: 'none',
        display_slot_selection: 'block'
      },
      screen: "cottage_booking",
      termsAndConditionChecked: false,
      message: "No time slots available",
      preload_image: "",
      file_name: "",
      formData: {
        start_date: null,
        end_date: null,
        amount: 0,
        selectedUnits: 0,
        adult_count: 0,
        child_count: 0,
        total_amount: 0,
        cgst_amount: 0,
        sgst_amount: 0
      },
      media_id: [],
      img_selection_index: null,
      detailsData: {
        pax: null,
        discounts: [],
        property_name: "",
        is_pg_allow: false,
        is_upload_document_allow: false,
        booking_error_message: "",
        max_adult_count: 0,
        max_child_count: 0,
        enabled_terms: 1
      }
    };

    this.dateSelectionChoice = [
      { id: 1, name:'Today' },
      { id: 2, name:'Tommorow' },
      { id: 3, name:'Custom' },
    ]
  }
  
  handleTermsAndConditionOnChange() {
    this.setState((prevState) => ({
      ...prevState,
      termsAndConditionChecked: !prevState.termsAndConditionChecked
    }));
  }

  async details(){
    console.log("Getting required information")
    let formData = {
      id: parseInt(window.location.href.split("timeselection/")[1]),
      token: localStorage.getItem("societyToken"),
      start_date: this.state.formData.start_date ? this.state.formData.start_date.format('YYYY-MM-DD') : null,
      end_date: this.state.formData.end_date ? this.state.formData.end_date.format('YYYY-MM-DD') : null
    };

    let api_options = {
      config: {
        method: 'post',
        url: FACILITY_DETAILS_URL,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback: (response)=>{
        console.log("=====================", response.data, "=====================");
        this.setState((prevState) => ({
          ...prevState,
          detailsData: {
            pax: response.data.property_info[0].pax, 
            discounts: response.data.property_info[0].discount, 
            property_name:response.data.property_info[0].name,
            is_pg_allow: response.data.property_info[0].is_pg_allow === 1,
            is_upload_document_allow: response.data.property_info[0].is_upload_document_allow === 1,
            booking_error_message: response.data.booking_not_avaliable,
            max_child_count: response.data.property_info[0].max_child_count,
            max_adult_count: response.data.property_info[0].max_adult_count,
            enabled_terms: response.data.property_info[0].enabled_terms === 1
          },
          formData: {
            ...prevState.formData,
            amount: response.data.property_info[0].price[0].amount,
            total_amount: response.data.property_info[0].price[0].total_amount,
            cgst_amount: response.data.property_info[0].price[0].cgst_amount,
            sgst_amount: response.data.property_info[0].price[0].sgst_amount
          },
        }));
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
  
    apiCall(api_options);
  };

  async save() {
    const totalDays = this.state.formData.end_date.diff(this.state.formData.start_date, 'days');
    const data = new FormData();

    data.append("id", parseInt(window.location.href.split("timeselection/")[1]));
    data.append("start_date", this.state.formData.start_date ? this.state.formData.start_date.format('YYYY-MM-DD') : null);
    data.append("end_date", this.state.formData.end_date ? this.state.formData.end_date.format('YYYY-MM-DD') : null);
    data.append("token", localStorage.getItem("societyToken"));
    data.append("amount", parseInt(this.state.formData.amount));
    data.append("selectedUnits", parseInt(this.state.formData.selectedUnits));
    data.append("totalAmount", parseInt(this.state.formData.amount)*parseInt(Math.max(1, totalDays)));
    data.append("adult_count", parseInt(this.state.formData.adult_count));
    data.append("child_count", parseInt(this.state.formData.child_count));
    data.append("media_id", JSON.stringify(this.state.media_id));

    var api_options = {
      config: {
        method: "post",
        url: AMENITY_BOOKINGS_SAVE_URL,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data:data,
      },
      successCallback: (response)=>{
        console.log("sucess");
        window.location.href = response.data.url;
        // this.props.navigator(-1)
      },
      errorCallback: (error)=>{
        console.log("Error", error.response);
        let config = {
          message: error.response.data.message,
          confirm_btn_txt: "Yes",
          cancel_btn_show: false,
          icon: 'warning'
        }
        SweetAlert(config);
      }    
    }
    apiCall(api_options);
    
  }

  sendUploadAction(index) {
    this.setState((prevState) => ({
      ...prevState,
      img_selection_index: index
    }));

    var shareObj = { 
      "type": "doc",
      "page_name": "adhar_upload"                                  
    }; 

    //console.log(shareObj);       
     
    if(isIOS){
      window.webkit.messageHandlers.sendUploadActionInNative.postMessage(JSON.stringify(shareObj));  
    }        

    if(isAndroid){          
      window.JSBridge.sendUploadActionInNative(JSON.stringify(shareObj)); 
    }
   
  }

  addExternalJS() {
    var currentdate = new Date(); 
    const script = document.createElement("script");
    script.src = "https://fm-admin.hoabl.in/front/external.js?t=" + currentdate.getTime();
    script.async = true;
    document.body.appendChild(script);

    window.gatepass_obj = this;
  }

  submitForm = (e) => {
    e.preventDefault()
    this.save();
  }

  componentDidMount() {
    this.addExternalJS();
    this.details();
  };

  componentDidUpdate(prevProps, prevState) {
    if((prevState.formData.start_date !== this.state.formData.start_date) || (prevState.formData.end_date !== this.state.formData.end_date)) {
      this.details();
    }

    if (prevState.formData.adult_count !== this.state.formData.adult_count) {
      console.log("Updating media_id array")
      if (prevState.formData.adult_count < this.state.formData.adult_count) {
        this.setState((oldValue) => ({
          ...oldValue,
          media_id: [...oldValue.media_id, null]     
        }));
      }
      else {
        this.setState((oldValue) => ({
          ...oldValue,
          media_id: [...oldValue.media_id.slice(0, -1)]     
        }));
      }
    }
  }

  downloadPDF(fileName) {
    if (fileName) {
      var obj = {
          url: AADHAR_IMG_URL + fileName
      }
      
      if(isIOS){
          return window.webkit.messageHandlers.sendDownloadActionInNative.postMessage(JSON.stringify(obj));  
      }
      else if(isAndroid){
        return  window.JSBridge.sendDownloadActionInNative(JSON.stringify(obj));
      }
      else{
        window.open(AADHAR_IMG_URL + fileName, "_blank");
      }
    }
  }

  render() {
    return (
      <>
        <Header
          societyName={this.state.styles.display_preview === 'none' ? "Check Availability":"Confirm Your Stay"}
          handleGoBack={(this.state.styles.display_preview === 'block' && this.state.styles.display_slot_selection === "none") ? () => {
            this.setState((prevState) => ({
              ...prevState,
              styles: {
                ...prevState.styles,
                display_preview: "none",
                display_slot_selection: "block"
              }
            }))
          } : undefined}
        />

        <div className="container" style={{display: this.state.styles.display_slot_selection}}>
          
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{marginTop: "1rem"}}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  className='text-field-radious'
                  disablePast={true}
                  label="Check In"
                  name="start_date" 
                  inputFormat={date_format}
                  value={this.state.formData.start_date}
                  onChange={(e)=>{
                    this.setState((prevState) => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        start_date: e,
                        selectedUnits: 0,
                        child_count: 0,
                        adult_count: 0
                      }
                    }))
                  }}
                  renderInput={ (params) => <TextField {...params} variant="standard" /> }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sx={{marginTop: "1rem"}}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  className='text-field-radious'
                  label="Check Out"
                  name="end_date" 
                  inputFormat={date_format}
                  minDate={moment.max(moment(), this.state.formData.start_date ? this.state.formData.start_date : moment() )}
                  value={this.state.formData.end_date}
                  onChange={(e)=>{
                    this.setState((prevState) => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        end_date: e,
                        selectedUnits: 0,
                        child_count: 0,
                        adult_count: 0
                      }
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} variant="standard" />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6} sx={{marginTop: "1rem"}}>
              <Typography variant="h6" sx={{fontFamily:'Jost'}} >Rooms</Typography>
              <TextField
                className='text-field-radious'
                select
                fullWidth
                variant='outlined'
                id="unit-selection"
                label="Select"
                helperText={this.state.detailsData.pax === 0 ? this.state.detailsData.booking_error_message : ""}
                error={this.state.detailsData.pax === 0}
                value={this.state.formData.selectedUnits === 0 ? "" : this.state.formData.selectedUnits}
                onChange={(event) => this.setState(prevState => ({
                  ...prevState,
                  formData: {
                    ...prevState.formData,
                    selectedUnits: event.target.value,
                    child_count: 0,
                    adult_count: 0
                  }
                }))}
              >
                { [...Array(this.state.detailsData.pax === null ? 0 : this.state.detailsData.pax).keys()]
                    .map((unit) => (
                      <MenuItem key={unit} value={unit+1}> {unit + 1} </MenuItem> 
                    ))
                }
              </TextField>
            </Grid>

            <Grid item xs={12} sx={{marginTop:'1rem'}}>
              <CounterButton
                label={"Adults"}
                value={this.state.formData.adult_count}
                maxValue={this.state.detailsData.max_adult_count * this.state.formData.selectedUnits}
                onIncrement={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    formData: {
                      ...prevState.formData,
                      adult_count: prevState.formData.adult_count + 1
                    }
                  }))
                }}
                onDecrement={() => (this.setState((prevState) => ({
                  ...prevState,
                  formData: {
                    ...prevState.formData,
                    adult_count: prevState.formData.adult_count - 1
                  }
                })))}
                onChange={(event) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    formData: {
                      ...prevState.formData,
                      adult_count: Math.max(Number(event.target.value), 1)
                    }
                  }))
                }}
                minValue={0}
              />
            </Grid>

            <Grid item xs={12} sx={{marginTop:'1rem'}}>
              <CounterButton
                label={"Children"}
                subTitle={"(below 6yrs)"}
                value={this.state.formData.child_count}
                maxValue={this.state.detailsData.max_child_count * this.state.formData.selectedUnits}
                onIncrement={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    formData: {
                      ...prevState.formData,
                      child_count: prevState.formData.child_count + 1
                    }
                  }))
                }}
                onDecrement={() => (this.setState((prevState) => ({
                  ...prevState,
                  formData: {
                    ...prevState.formData,
                    child_count: prevState.formData.child_count - 1
                  }
                })))}
                onChange={(event) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    formData: {
                      ...prevState.formData,
                      child_count: Math.max(Number(event.target.value), 0)
                    }
                  }))
                }}
                minValue={0}
              />
            </Grid>

            { (this.state.detailsData.is_upload_document_allow) && (
              <>
                <Grid item xs={12} sx={{marginTop:'1.5rem'}}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h5" sx={{fontFamily:'Jost', mr: 1}} >
                      Upload document 
                    </Typography>
                  
                  </Box>
                  <Typography  variant="subtitle1"  sx={{fontFamily:'Jost', color: "rgba(157, 158, 177, 1)"}} >
                    You can submit 1 of the docs: Aadhaar card/DL/Passport.
                    Only PDF, JPEG, and PNG files will be accepted. Max size: 5 MB.
                  </Typography>
                </Grid>

                {
                  [...Array(this.state.formData.adult_count).keys()].map((index) => (
                    <Grid key={index} item xs={12} sx={{marginTop: "1rem"}}>
                      <AdharUpload
                        label={`Adult ${index+1}`}
                        onClick={ () => { this.sendUploadAction(index) }}
                        onChange={(file_name) => {
                          console.log("Uploaded file: ", file_name);
                          const media_id_list = this.state.media_id;
                          media_id_list[index] = file_name;
                          this.setState((prevState) => ({
                            ...prevState,
                            media_id: [...media_id_list]
                          }));
                        }}
                        fileName={this.state.media_id[index]}
                        downloadPDF={() => this.downloadPDF(this.state.media_id[index])}
                      />
                    </Grid>
                  ))
                }
              </>
            )}


          </Grid>

          <Box sx={{marginTop: "1rem"}}>
            { this.state.detailsData.enabled_terms && 
              <TermsAndConditions
                value={this.state.termsAndConditionChecked}
                onChange={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    termsAndConditionChecked: !prevState.termsAndConditionChecked
                  }));
                }}
                id={parseInt(window.location.href.split("timeselection/")[1])}
              />
            }

            <Button
              disabled={(this.state.detailsData.is_pg_allow && this.state.detailsData.pax === 0) || (!this.state.termsAndConditionChecked) || this.state.formData.selectedUnits === 0 || this.state.formData.adult_count === 0}
              onClick={()=>{
                this.setState((prevState) => ({
                  ...prevState,
                  styles: {...prevState.styles, display_preview: 'block', display_slot_selection: 'none'}
                }));

                const data = {
                  id: parseInt(window.location.href.split("timeselection/")[1]),
                  start_date: this.state.formData.start_date ? this.state.formData.start_date.format('YYYY-MM-DD') : null,
                  end_date: this.state.formData.end_date ? this.state.formData.end_date.format('YYYY-MM-DD') : null,
                  token: localStorage.getItem("societyToken"),
                  amount: parseInt(this.state.formData.amount),
                };
                
                Mixpanel.track("fm_cottage_book_now_clicked", data);
              }} 
              sx={{background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)', fontFamily:'Jost'}}
              fullWidth  variant="contained"
            >
              { this.state.detailsData.is_pg_allow ? "Pay Now" : "Book Now" }
            </Button>
            <Button
              onClick={()=>window.location.reload()} 
              sx={{ marginBottom:'2rem',marginTop:'1rem', fontFamily:'Jost'}}
              fullWidth
              variant=""
            >
              Cancel
            </Button>

          </Box>

        </div>

        {/* Confiramtion Screen on book now click make it visible less hide it */}
        <div style={{display: this.state.styles.display_preview, ...styles.container}}>
          <Typography variant='h6' style={styles.label}>Guest Room:</Typography>
          <div style={styles.keyTextContainer}>
            <Typography variant='h6' style={styles.keyText}>{this.state.detailsData.property_name}</Typography>
          </div>

          <Typography variant='h6' style={styles.label}>Check In Date:</Typography>
          <div style={styles.keyTextContainer}>
            <Typography variant='h6' style={styles.keyText}>{this.state.formData.start_date ? this.state.formData.start_date.format(date_format) : "-"}</Typography>
          </div>

          <Typography variant='h6' style={styles.label}>Check Out Date:</Typography>
          <div style={styles.keyTextContainer}>
            <Typography variant='h6' style={styles.keyText}>{this.state.formData.end_date ? this.state.formData.end_date.format(date_format) : "-"}</Typography>
          </div>

          <Typography variant='h6' style={styles.label}>Adults:</Typography>
          <div style={styles.keyTextContainer}>
            <Typography variant='h6' style={styles.keyText}>{this.state.formData.adult_count}</Typography>
          </div>

          <Typography variant='h6' style={styles.label}>Children:</Typography>
          <div style={styles.keyTextContainer}>
            <Typography variant='h6' style={styles.keyText}>{this.state.formData.child_count}</Typography>
          </div>
            
          <Typography variant='h6' style={styles.label}>Total Price:</Typography>
          <div style={styles.keyTextContainer}>
            <Typography variant='h6' style={styles.keyText}>
              Amount : ₹ { this.state.formData.start_date && this.state.formData.end_date && 
              (
                parseFloat(this.state.formData.amount, 10) *
                parseInt(this.state.formData.selectedUnits) * 
                Math.max(1, this.state.formData.end_date.diff(this.state.formData.start_date, 'days'))
              ).toFixed(2) }
            </Typography>

            <Typography variant='h6' style={styles.keyText}>
              CGST : ₹ { this.state.formData.start_date && this.state.formData.end_date && 
              (
                parseFloat(this.state.formData.cgst_amount) *
                parseInt(this.state.formData.selectedUnits) *
                Math.max(1, this.state.formData.end_date.diff(this.state.formData.start_date, 'days'))
              ).toFixed(2) }
            </Typography>

            <Typography variant='h6' style={styles.keyText}>
              SGST : ₹ { this.state.formData.start_date && this.state.formData.end_date && 
              (
                parseFloat(this.state.formData.sgst_amount) *
                parseInt(this.state.formData.selectedUnits) *
                Math.max(1, this.state.formData.end_date.diff(this.state.formData.start_date, 'days'))
                ).toFixed(2) }
            </Typography>
      
            <Typography variant='h6' style={styles.keyText}>
              Total : ₹ { this.state.formData.start_date && this.state.formData.end_date && 
              (
                parseFloat(this.state.formData.total_amount) *
                parseInt(this.state.formData.selectedUnits) *
                Math.max(1, this.state.formData.end_date.diff(this.state.formData.start_date, 'days'))
              ).toFixed(2) }
            </Typography>
          </div>

          <Typography variant='h6' style={styles.label}>Rooms:</Typography>
          <div style={styles.keyTextContainer}>
            <Typography variant='h6' style={styles.keyText}> {this.state.formData.selectedUnits === 0 ? "Not Selected any units" : this.state.formData.selectedUnits}</Typography>
          </div>

          <Button
            onClick={(e)=>this.submitForm(e)}
            sx={{background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)', marginTop:'1rem', fontFamily:'Jost'}}
            fullWidth
            variant="contained"
          >
            Confirm
          </Button>

          <Button
            onClick={()=>this.setState((prevState) => ({...prevState, styles: {...prevState.styles, display_preview: 'none', display_slot_selection: 'block'}}))}
            sx={{ marginBottom:'1rem',marginTop:'1rem', fontFamily:'Jost'}}
            fullWidth
            variant=""
          >
            Cancel
          </Button>
        </div>
      </>
    )
  }
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigator={useNavigate()} />;
}

export default withParams(CottageTimeSelection);

// Inactive Time Slot are already taken by other people